// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const account = await store
      .getState()
      .blockchain.account;
      let tokenSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let balanceOf = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account)
        .call();
      let mintActive = await store
        .getState()
        .blockchain.smartContract.methods.mintActive()
        .call();
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();
      
      let presaleActive = await store
        .getState()
        .blockchain.smartContract.methods.presaleActive()
        .call();
      
      let MAX_PEZZI_WHITELIST_CAP = await store
        .getState()
        .blockchain.smartContract.methods.MAX_PEZZI_WHITELIST_CAP()
        .call();

      dispatch(
        fetchDataSuccess({
          tokenSupply,
          balanceOf,
          mintActive,
          presaleActive,
          MaxWLMint: MAX_PEZZI_WHITELIST_CAP,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
