import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
  z-index: 1;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  z-index: 1;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const PlainText = styled.a`
  color: white;
  text-decoration: none;
`;

export const StyledVideo = styled.video`
position: fixed;
width: 100%;
height: 100%;
z-index: 0;`;

export const SuccessText = styled.p`
color: green;
`;

export const WarningText = styled.p`
color: red;
`;

export const StyledInput = styled.input`
height: 30px;
width: 100%;
margin-bottom: 16px;
`;
function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [address, setAddress] = useState(0);
  const [whitelisted, setWhitelisted] = useState(false);
  const [checked, setChecked] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 2,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, ["0x581ddECBf2E27a06A069D67Fc7fb185eFB3c3d5f" , "0xDC1e8E9eAC1f3d16fDE36f9C85c99e469f93dD1D" , "0x8EdADbE0B48655073C3De8b2F35e315025Ac8Ed5" , "0x5986554fd85E8c4A40B03C95c44FF2b3f408967C" ,"0x5A19EA9e42c67eF4956e527B3db96B9B4E663E79" , "0xBf099768D7813b9d8F8e5c0CFbE6101158e94097" , "0x6f0f7d222683b8b2082dcba950cfbf033ddbb06c" , "0xE1A8C83A0D11F591DAB8eA1F89CA810b67B29B9a" , "0xB3a0E9e177d792bD1c7330E4B3A94D38DF0CE1C0" , "0xD1EfC9fBe559607D17B6313bf2Ad3DC02B612FeE" , "0x7828757bA24fB8DF83435F177B7D88d82343C69D" , "0x562AA5751F65057d25F12F1fb7f1e735E1953048" , "0x7385e3e29cc1E195e0c1663907562214fe81527f" , "0xC0072c5793771a7563F43698a4710Ba56BD6d9a8" , "0x2D4b5d114c9110C2640f82fce8CCC2e8bFdBDF95" , "0xd1c2c1eB4e3469F35769d7fb354fBD531b6e9c91" , "0x87D0608903A7eFb4320D1DDa92F1A37194b0c37A" ,"0xA27B19f34734c0E822bf6Cca89f2A7FbE6E2ADCA" , "0x4462065624eC1f614cDff21aaD86AD0e716FF4be" , "0x5b0D7193655017BF976a7012Be07b4C79347EdA8" ,"0xA68eB536cA1C0655412b6602D6D92Aa0E22E6663" , "0xBDB4b7FB55Ace6a7A1c1Da737C64Faa576a96856" , "0x5e4Ca1d26487501362F58eaf32499C5E6C7D5C8A" , "0x6d3B8DED98d79190A4eaF1d4abE20DD3DFB173A9" , "0x00566D1ed5A6aA6f959b558F457f1C77Bd76CEB6" ,"0x6DD8B50c786D820281637B5A58f51D4d76804F82" , "0xa0843e25a5c5394c41265Bc8525a54ddcB6a7496" , "0x1939CB6073d323D61f30E061aBc869DD6Cdb4354" , "0x991D8fD15D403b1bCb2e23a787fF175DE007174E" , "0x4F9cC76f6E607F35a6a2F1C4bdce0608Bfb003d3" ,"0x465E2a5228DDd5c1cA8e4EDfC8AB676b07e0Ed92" , "0x170Fa4320CEd15ceadb2567c1f8Fe254A974Bf19" , "0xdF269915Bc019A4305237007F1b418351252B43c" , "0xACeF7f530b4691195d6818236E3C98e431965243" , "0xA68eB536cA1C0655412b6602D6D92Aa0E22E6663" , "0xD03BE38bFfF4164294EBD77FcD29363dcE5A47FB" , "0x8621a360720e21Ea8e16AA0ABb8cF29Ae4B6A4e7"]);

  useEffect(() => {
    getData();
  }, [blockchain.account]);
  // <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />

  const checkMerkle = async () => {
      if(!address) {return false;}
    let res = await fetch(`https://97ompq13te.execute-api.us-west-1.amazonaws.com/default/${address}`, {
        "body": null,
        "method": "GET",
        "mode": "cors",
        "credentials": "omit"
      });
  
      let proof = await res.json();
  
      if(proof.length > 0){
        setWhitelisted(true);
      }else {
        setWhitelisted(false);
      }

      setChecked(true);
  }

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--bg)" }}
      >
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24, alignItems: "center"  }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "100%",
              minHeight: 400,
              maxHeight: 500,
              height: "100%",
              maxWidth: 500,
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.8)"
            }}
          >
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
                <PlainText>
                Enter your wallet address to check if you are whitelisted!
                </PlainText>
                <StyledInput onChange={v => setAddress(v.target.value)}/>
                <br/>
                <StyledButton onClick={e => checkMerkle()}>Check</StyledButton>
                <br/><br/>
                <PlainText>
                    <SuccessText>
                    {whitelisted ? "You are whitelisted!" : null}
                    </SuccessText> 
                    <WarningText>
                    {!whitelisted && checked ? "Sorry, you are not whitelisted. If you believe this is a mistake, please contact us on Discord!" : null}
                    </WarningText>
                </PlainText>
            </s.TextDescription>
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}

export default App;
